import React, { useState } from "react"
import { Box, Button, Grid, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import loginLogo from "../../../images/drawer_ic.svg"
import CustomTextField from "../../components/CustomTextField"
import { Link as NavLink, navigate } from "gatsby"
import Loader from "../../components/Loader/Loader"
import { ButtonColor } from "../../../helper/ButtonColor"
import { useDispatch } from "react-redux"
import { signInWithEmailAndPass } from "../../../redux/slices/userSlice"
import { COLORS } from "../../../utils/theme"

const useStyles = makeStyles(theme => ({
  titleLogin: {
    fontSize: "24px",
    fontStyle: "normal",
    color: "#0A3133",
    marginTop: "35px",
  },
  descLogin: {
    fontSize: "17px",
    fontStyle: "normal",
    wordWrap: "normal",
    color: "#0A3133",
    textAlign: "center",
  },
  spanText: {
    color: COLORS.green,
    fontSize: "14px",
    fontWeight: "bold",
  },
  spanBottomText: {
    fontFamily: "DM Sans",
    color: COLORS.darkGray5,
    fontSize: "12px",
  },
  modalBottom: {
    color: "#000000",
    background: "#F4F5F7",
    width: "100%",
    height: "70px",

    [theme.breakpoints.down("md")]: {
      padding: "0 5px",
      marginTop: "0px",
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "15px",
      marginTop: "5px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "15px",
      marginTop: "5px",
    },
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
  },
  loginBtn: {
    background: `${ButtonColor}`,
    color: "#0A3133",

    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.up("lg")]: {
      marginTop: "10px",
    },
    width: "100%",
    textTransform: "none",
    borderRadius: "2px",
    "&:hover": {
      background: `${ButtonColor}`,
    },
  },
  createAccBtn: {
    background: `${COLORS.green}`,
    color: "white",

    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginBottom: "15px",
      marginTop: "0px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "20px",
      marginTop: "10px",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "20px",
      marginTop: "10px",
    },

    textTransform: "none",
    borderRadius: "6px",
    "&:hover": {
      background: `${COLORS.green}`,
    },
    height: "50px",
  },
  gridForm: {
    [theme.breakpoints.down("md")]: {
      marginBottom: "10px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "30px",
    },

    width: "100%",
  },
  bottomSignup: {
    fontFamily: "DM Sans",
    fontSize: "12px",
    color: "black",
    opacity: 0.5,
  },
  errorText: {
    color: "#FD6E84",
    fontSize: "12px",
  },
}))

function LoginDialog() {
  const classes = useStyles()

  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [emailError, setEmailError] = useState("")
  const [passwordError, setPasswordError] = useState(false)
  const [errorMessage, setErrorMessage] = useState({ status: false })
  const [isDisabled, setIsDisabled] = useState(true)

  const validateEmail = value => {
    const regex = /^\S+@\S+\.\S+$/
    if (!regex.test(value)) {
      setEmailError("Please enter a valid email address")
    } else {
      setEmailError("")
    }
  }

  const validatePassword = value => {
    if (value.length < 6) {
      setIsDisabled(true)
      setPasswordError("Password must be at least 6 characters long")
    } else {
      setIsDisabled(false)
      setPasswordError("")
    }
  }

  const handleEmailInput = data => {
    validateEmail(data)
    setEmail(data)
  }

  const handlePasswordInput = data => {
    validatePassword(data)
    setPassword(data)
  }

  const handleSubmit = async () => {
    setLoading(true)
    try {
      let user = await dispatch(signInWithEmailAndPass({ email, password }))
      if (!user.error) {
        navigate("/venue/schedule")
      } else {
        setLoading(false)
        if (user.error.message === "Firebase: Error (auth/wrong-password).") {
          setErrorMessage({
            status: true,
            message: "Your password is incorrect",
          })
        }
        if (user.error.message === "Firebase: Error (auth/user-not-found).") {
          setErrorMessage({
            status: true,
            message: "Enter correct credentials",
          })
        }
        if (user.error.message === "Firebase: Error (auth/invalid-email).") {
          setErrorMessage({
            status: true,
            message: "Enter correct email",
          })
        }
      }
    } catch (error) {
      console.log("loginModalError")
    }
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <Box hideBackdrop={true} sx={{ top: "20px", overflow: "hidden" }}>
        <Grid
          style={{ padding: "0px 20px" }}
          container
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            justifyContent="center"
            alignItems="center"
            container
            direction="column"
          >
            <img src={loginLogo} alt="" />

            <Typography className={classes.titleLogin}>Club Manager</Typography>
          </Grid>
          <Grid
            item
            justifyContent="center"
            alignItems="center"
            container
            style={{ marginBottom: "15px" }}
          >
            <Typography
              className={classes.descLogin}
              variant="p"
              noWrap={false}
            >
              Welcome!{" "}
              <span className={classes.spanText}>Thousands of people </span>are
              waiting to see what your club has to offer. Manage your facility
              here.
            </Typography>
          </Grid>

          <Grid justifyContent="center" alignItems="center" container>
            <Grid
              direction="column"
              justifyContent="center"
              alignItems="center"
              className={classes.gridForm}
            >
              <CustomTextField
                placeHolder="Email"
                onChange={handleEmailInput}
                value={email}
                error={emailError}
              />
              {emailError && (
                <Typography className={classes.errorText}>
                  {emailError}
                </Typography>
              )}
              <CustomTextField
                placeHolder="Password"
                onChange={handlePasswordInput}
                type="password"
                value={password}
                error={passwordError}
              />
              {errorMessage.status && (
                <Typography className={classes.errorText}>
                  {errorMessage.message}
                </Typography>
              )}
            </Grid>
            <Button
              disableElevation
              disableTouchRipple
              disableRipple
              variant="contained"
              className={classes.createAccBtn}
              onClick={handleSubmit}
              disabled={isDisabled ? true : false}
            >
              Login
            </Button>
          </Grid>
        </Grid>
        <div style={{ height: "100%", width: "100%" }}>
          <Grid
            justifyContent="center"
            alignItems="center"
            container
            className={classes.modalBottom}
          >
            <NavLink
              style={{ textDecoration: "none" }}
              className={classes.bottomSignup}
              to="/venue/welcome"
            >
              Dont have an account? Sign up{" "}
              <span
                className={classes.spanBottomText}
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "black",
                }}
              >
                here
              </span>
            </NavLink>
          </Grid>
        </div>
      </Box>
    </>
  )
}

export default LoginDialog
