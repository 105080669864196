import React from "react"
import { InputBase, FormControl } from "@material-ui/core"
import { withStyles, makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  rootStyle: {
    width: "100%",
    marginLeft: "0px",
  },
}))

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
      width: "100%",
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#F2F2F2",
    border: "none",
    fontSize: 16,
    marginTop: "10px",
    marginBottom: "10px",
    padding: "10px 12px",
    marginRight: "10px",
    border: props =>
      props.error ? "1.5px solid #FD6E84 " : "1.5px solid white",
    fontFamily: "DM Sans",
    fontFamily: ["DM Sans"].join(","),
  },
}))(InputBase)

function CustomTextField({
  placeHolder,
  onChange,
  type,
  required,
  error,
  value,
}) {
  const classes = useStyles()

  return (
    <FormControl className={classes.rootStyle}>
      <BootstrapInput
        id="bootstrap-input"
        placeholder={placeHolder}
        onChange={e => onChange(e.target.value)}
        type={type}
        value={value}
        required={required}
        error={error}
      />
    </FormControl>
  )
}

export default CustomTextField
